<template>
  <div class="fragment">
    <div
        class="mb-4 mt-4 font-weight-bold"
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_HistoryOfChanges',])"></div>
      {{$t('common_HistoryOfChanges.localization_value.value')}}

      <div>
        <MainButton
            class="order-create__footer-btn-i mt-3"
            :value="$t('common_print.localization_value.value')"
            @click.native="print"
        />
      </div>

    </div>


    <div class="site-table-wrap table-small history-table-small">
      <div class="history-table__wrap">
        <div class="history-table">
          <div class="history-table__head">
            <div class="history-table__row">
              <div class="history-table__col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" style="top: 3px" @click="editTranslate(['common_historyDate',])"></div>
                {{$t('common_historyDate.localization_value.value')}}
              </div>
              <div class="history-table__col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" style="top: 3px" @click="editTranslate(['common_historyUsers',])"></div>
                {{$t('common_historyUsers.localization_value.value')}}
              </div>
              <div class="history-table__col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" style="top: 3px" @click="editTranslate(['common_historyAction',])"></div>
                {{$t('common_historyAction.localization_value.value')}}
              </div>
              <div class="history-table__col"></div>
            </div>
          </div>
          <div class="history-table__body">
            <div class="fragment"
                 v-for="(item, i) in historyChanges"
                 :key="i">
              <HistoryOfChangesTableItem
                  :pevItem="i === 0 ? null : historyChanges[i-1]"
                  :item="item"
                  :type="'orderNotification'"
                  :defaultShow="false"
              />
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HistoryOfChangesTableItem
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/components/HistoryOfChangesTable/HistoryOfChangesTableItem/HistoryOfChangesTableItem";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
export default {
name: "HistoryOfChangesComponent",
  components: {MainButton, HistoryOfChangesTableItem},



  data() {
    return {
      historyChanges: []
    }
  },

  mounted() {
    // console.log(this.type);
    // console.log(this.uuid);
    this.$store.dispatch('getHistoryChanges', this.$route.query.uuid).then(response => {
      this.historyChanges = this.getRespData(response)?.activities
    })
  },

  methods: {
    print() {
      window.print()
    },
  },

}
</script>

<style scoped>
  .history-table-small{
    max-width: 800px;
  }

  .history-table-small >>> .history-table__sub-col:nth-child(3){
    width: 100%;
    max-width: 100%;
  }
</style>


<style media="print">

.print-btn-head{
  max-width: 167px;
}

@page { size: portrait; }

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  body .noPrintable {
    display: none;
  }

  body .onlyPrintable {
    display: block !important;
  }

  .default-layout__page-wrap{
    padding-left: 0 !important;
  }
  .manager-btn{
    display: none !important;
  }

  .max-200{
    max-width: 200px !important;
  }

  .max-40{
    max-width: 40px !important;
    width: 40px !important;
  }

  .header__inner{
    display: none !important;
  }

  .default-layout__page-content{
    margin-top: 30px;
  }

  .site-table-wrap.table-small,
  .site-table {
    /*width: initial !important;*/
  }


  .site-table-wrap.table-small th{
    width: initial !important;
  }

  .site-table-wrap.table-small td:nth-child(2),
  .site-table-wrap.table-small th:nth-child(2){
    width: 20px !important;
    /*background: blue;*/
  }

  .site-table-wrap.table-small td:nth-child(3),
  .site-table-wrap.table-small th:nth-child(3){
    width: 200px !important;
    /*background: red;*/
  }

  .site-table-wrap.table-small td:nth-child(4),
  .site-table-wrap.table-small th:nth-child(4){
    width: 200px !important;
  }

  .order-admin-products .site-table-wrap{
    width: initial !important;
    max-width: initial!important;
    overflow: hidden !important;
  }

  .site-table{
    width: initial !important;
    max-width: initial!important;
    overflow: hidden !important;
  }

  .site-table-wrap.table-small td:first-child,
  .site-table-wrap.table-small td:last-child,
  .site-table-wrap.table-small th:first-child,
  .site-table-wrap.table-small th:last-child{
    display: none;
  }

  .search-engine-block__inner{
    display: none !important;
  }

}
</style>
